// 常量
const CONSTANT = {
  SUBMIT_MEAL_PERIOD: [
    {
      label: '早餐',
      value: 'breakfast',
      money: 'breakfastMoney'
    },
    {
      label: '午餐',
      value: 'lunch',
      money: 'lunchMoney'
    },
    {
      label: '晚餐',
      value: 'dinner',
      money: 'dinnerMoney'
    },
    {
      label: '夜宵',
      value: 'night',
      money: 'nightMoney'
    }
  ]
};

export default CONSTANT;
